import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { NGXLogger } from 'ngx-logger';
import { Tariff } from '../common/dtos/tariff.dto';

/**
 * The Charging service supplies data to subscribers by attempting to call the captain api.
 * It stores successful calls in localStorage.
 * If the captain API call fails, it returns the last successful data retrieved from
 * localStorage
 */
@Injectable({
  providedIn: 'root',
})
export class ChargingService {
  public mapDataSubject: Subject<Site[]> = new Subject<Site[]>();
  public selectedSiteDataSubject: Subject<Site> = new Subject<Site>();
  public mapReadySubject: Subject<google.maps.Map> =
    new Subject<google.maps.Map>();
  public userGeolocationDataSubject: BehaviorSubject<google.maps.LatLng | null> =
    new BehaviorSubject<google.maps.LatLng | null>(null);
  private _currentlySelectedSite: Site = {} as Site;
  private serviceId: number = Math.floor(Math.random() * 1000000);
  constructor(
    private logger: NGXLogger,
    private http: HttpClient,
  ) {
    //this.logger.debug(`Charging Service Id: ${this.serviceId} constructed`);
  }

  /** This method will attempt to load the Site data from the localstorage
   * and parse it as a Site array. If it fails it returns an emtpy array
   */
  private getSiteDataFromCache(): Site[] {
    let returnValue: Site[] = [];
    try {
      const dataAsJson = localStorage.getItem('chargingServiceMapData');

      if (!dataAsJson) return [];

      returnValue = JSON.parse(dataAsJson) as Site[];

      return returnValue;
    } catch {
      return [];
    }
  }

  /** This method scans the localstorage Site data for a site which has a
   * friendly reference which matches.
   * @returns The site which matches - if not found, it returns null
   */
  public getSiteFromCacheByFriendlyReference(
    friendlyReference: string,
  ): Site | null {
    const theData = this.getSiteDataFromCache();

    if (!theData) return null;

    for (let i = 0; i < theData.length; i++) {
      const currentSite: Site = theData[i];

      if (currentSite.siteFR === friendlyReference) {
        return currentSite;
      }
    }

    return null;
  }

  /**
   *
   * @returns A Subject which will fire the 'next event when data comes in. It will always fire the
   * next event, as it will either get data from the url, or from local storage
   */
  public refreshMapData(): void {
    this.http
      .get<Site[]>(`${environment.captainApiUrl}/map/0`, {
        withCredentials: true,
      })
      .subscribe({
        next: (data) => this.onNextMapData(data),
        error: (e) => this.onErrorMapData(e),
      });
  }

  public set currentlySelectedSite(value: Site) {
    this._currentlySelectedSite = value;
    //this.logger.debug(`currently selected site set to: ${JSON.stringify(this._currentlySelectedSite)}`);
  }

  public get currentlySelectedSite() {
    return this._currentlySelectedSite;
  }

  /** This will be fired when the http client fires the 'next' method. The data received
   * will be from the body of the response. We store the data in localStorage and then
   * pass the data to the subscribers by firing the 'next' method
   */
  private onNextMapData(data: Site[]): void {
    //this.logger.info('refreshing');
    localStorage.setItem('chargingServiceMapData', JSON.stringify(data));
    this.mapDataSubject.next(data);
    return;
  }

  public onMapReadyData(data: google.maps.Map): void {
    //this.logger.info('refreshing');
    this.mapReadySubject.next(data);
    return;
  }

  /** This method is called when there is an error in the http client call
   * We attempt to load old data from localStorage and return that to subscribers
   * If this is not possible we return an empty array
   */
  private onErrorMapData(e: any): void {
    //this(e);
    //this.logger.error('MapData Error');
    const oldMapDataJSON: string | null = localStorage.getItem(
      'chargingServiceMapData',
    );
    if (!oldMapDataJSON) {
      this.mapDataSubject.next([]);
      return;
    }

    let mapData: Site[] = [];

    try {
      mapData = JSON.parse(oldMapDataJSON) as Site[];
    } catch (e) {
      this.mapDataSubject.next([]);
      return;
    }

    this.mapDataSubject.next(mapData);
  }

  nextUserGeolocationData(geolocation: google.maps.LatLng) {
    this.userGeolocationDataSubject.next(geolocation);
    return;
  }
}

//#region MapData interfaces
/** These are the data structures returned from the API call
 * Sites have many ConnectionPoints which have a single ChargeStation which has many Connectors
 */
export interface Site {
  id: string;
  siteName: string;
  siteAddress: string;
  siteFR: string;
  siteLat: number;
  siteLong: number;
  conPoints: ConnectionPoint[];
}

export interface ConnectionPoint {
  conPointFR: string;
  conPointLong: number;
  conPointLat: number;
  chargeStation: ChargeStation;
  connectionPointId: string;
  aquaLinkEnabled: boolean;
  accessible: boolean;
}

export interface ChargeStation {
  chargeStationModel: string;
  chargeStationConnectors: Connector[];
  chargeStationAquaLinkEnabled: boolean;
}

export interface Connector {
  no: number;
  kw: number;
  shape: number;
  status: string;
  tariff: Tariff;
  position?: string;
}

//#endregion
