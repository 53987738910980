import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsLoaderService {
  private static isLoaded = false;
  private static loadPromise: Promise<void>;

  public load(): Promise<void> {
    if (GoogleMapsLoaderService.isLoaded) {
      return GoogleMapsLoaderService.loadPromise;
    }

    GoogleMapsLoaderService.loadPromise = new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC4JTvKlINGYty6LjOY-ZmF8ti4Sn8E7qM&&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        GoogleMapsLoaderService.isLoaded = true;
        resolve();
      };
      script.onerror = () => reject('Google Maps script loading failed');
      document.head.appendChild(script);
    });

    return GoogleMapsLoaderService.loadPromise;
  }
}
