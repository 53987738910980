import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  OnInit,
  EventEmitter,
  Input,
  PLATFORM_ID,
  Inject,
} from '@angular/core';
import { ChargingService } from '../../../app/charging/charging.service';
import { GoogleMapsLoaderService } from './google-map-loader.service';

@Component({
  selector: 'app-google-map',
  template: '',
})
export class GoogleMapComponent implements OnInit {
  @Input() mapOptions!: google.maps.MapOptions;
  public _el!: HTMLElement;
  public _map!: google.maps.Map;
  public $mapReady: EventEmitter<any> = new EventEmitter();
  public _mapIdledOnce = false;

  constructor(
    public _elementRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId: object,
    private chargingService: ChargingService,
    private googleMapsLoaderService: GoogleMapsLoaderService,
  ) {}

  async ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      try {
        await this.googleMapsLoaderService.load();
        this.initMap();
      } catch (error) {
        console.error('Error loading Google Maps API:', error);
      }
    }
  }

  initMap(): void {
    this._el = this._elementRef.nativeElement;
    this._map = new google.maps.Map(this._el, this.mapOptions);
    const _ready_listener = this._map.addListener('idle', () => {
      if (!this._mapIdledOnce) {
        this.$mapReady.emit(this._map);
        this.chargingService.onMapReadyData(this._map);
        this._mapIdledOnce = true;
        google.maps.event.removeListener(_ready_listener);
      }
    });
  }
}
