import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { EditAddressDto } from './dtos/edit-address.dto';
import { CaptainProfileDto } from './dtos/captainProfile.dto';

@Injectable({
  providedIn: 'root',
})
export class EditProfileService {
  constructor(
    private httpClient: HttpClient,
    private toastController: ToastController,
  ) {}
  /**
   * Description
   * -
   * - Gets the user profile
   * @returns {Observable}
   */
  getMyProfile() {
    return this.httpClient.get(`${environment.apiURL}/cap/getMyProfile`, {
      withCredentials: true,
    });
  }

  /**
   * Description
   * -
   * - Saves the user's form input data to the database
   * @param {object} body the form data
   * @returns {Observable}
   */
  updateMyProfile(body: any) {
    return this.httpClient.patch(
      `${environment.apiURL}/cap/updateMyProfile`,
      body,
      {
        withCredentials: true,
        // needed because the response of this api call is a string - without it, the front-end throws an error
        responseType: 'json',
      },
    );
  }

  updateMyAddress(body: EditAddressDto) {
    return this.httpClient.patch(`${environment.apiURL}/cap/address`, body, {
      withCredentials: true,
      // needed because the response of this api call is a string - without it, the front-end throws an error
      responseType: 'json',
    });
  }

  sendChangePasswordEmail() {
    return this.httpClient.get(`${environment.apiURL}/auth/changePassword`, {
      withCredentials: true,
      // needed because the response of this api call is a string - without it, the front-end throws an error
      responseType: 'text',
    });
  }

  validateAddress(address: string, sessionToken: any): Observable<any> {
    return this.httpClient.post(
      `https://addressvalidation.googleapis.com/v1:validateAddress?key=AIzaSyC4JTvKlINGYty6LjOY-ZmF8ti4Sn8E7qM`,
      {
        address: {
          revision: 0,
          addressLines: [address],
        },
        sessionToken: sessionToken.vw,
      },
      {},
    );
  }
}
